<template>
  <div class="turntable">
    <div class="turn-content">
      <img src="@/assets/images/pdd/content.png" :class="['content', isTransition ? 'transition' : '']"
        :style="`transform: rotate(${turnRotate}deg);`" @transitionend="transitionend">
      <img src="@/assets/images/pdd/border.png" class="border">
      <img src="@/assets/images/pdd/active.png" class="active" v-show="isActive">
      <div class="turn-button" @click="tabBtn">
        <img src="@/assets/images/pdd/button.png" alt="">
        <div class="btn-info" ref="turnButton">
          <div v-if="!amountShow" class="btn-info-content">
            <p class="nums">{{ data.remain_turntable_count || 0 }}</p>
            <img src="@/assets/images/pdd/button-text.png" class="button-text">
          </div>
          <div v-else>
            <div v-if="amount!=0" class="flex-box">
              +
              <animate-number :formatter="formatNumber" from="0" :to="amount" :key="amount" duration="1500" easing="easeOutQuad" from-color="#fff" to-color="#fff"
                style="font-size:20px;text-align:center;display:block;"></animate-number>
            </div>
            <div v-else class="reward-icon">
              <img src="@/assets/images/pdd/spin-4.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="@/assets/images/vnd.png" class="jinbi-icon" :style="iconStyle" v-show="iconStyle" @animationend="animationend">
  </div>
</template>
  
<script>

export default {
  data() {
    return {
      turnRotate: 0,
      isTransition: false,
      baseRotate: 360 * 8,
      callBack: null,
      amountShow: false,
      amount: 0,
      type:1,
      isActive:false,
      iconStyle:null
    }
  },
  props: {
    data: {
      default: () => ({})
    },
    borderShow: {
      type: Boolean,
      default: true
    },
     border2Show: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    tabBtn() {
      this.$emit("tabBtn")
    },
    run(callBack, amount,type) {
      this.data.remain_turntable_count -=1
      this.callBack = callBack
      this.type = type
      if (this.isTransition) return
      this.isActive = false
      this.isTransition = true
      this.$nextTick(() => {
        if(amount==0){
          this.turnRotate = this.baseRotate + 45
        }else{
          if(type==1){
            // 现金
            this.turnRotate = this.baseRotate + 68
          }else{
            // 积分
            this.turnRotate = this.baseRotate - 23
          }
        }
        setTimeout(() => {
          this.amount = amount
          this.amountShow = true
          if(type==2){
            this.runIcon()
          }
          setTimeout(()=>{
            this.amount=0
            this.amountShow = false
          },3000)
        }, 5000)
      })
    },
    transitionend() {
      this.callBack && this.callBack()
      this.isTransition = false
      this.$nextTick(() => {
        this.isActive = true
        this.turnRotate = this.turnRotate - this.baseRotate
      })
    },
    runIcon(){
      const {x,y,width,height} = this.$refs.turnButton.getBoundingClientRect()
      const {x:x2,y:y2,width:width2,height:height2} = document.getElementById("balance").getBoundingClientRect()
      this.iconStyle ={
        "--left":`${x+width/2-15}px`,
        "--top":`${y+height/2-10}px`,
        "--target-left":`${x2+width2/2-15}px`,
        "--target-top":`${y2+height2/2-10}px`,
      }
      console.log(this.iconStyle)
    },
    animationend(){
      this.iconStyle = null
    }
  },
}
</script>
  
  
<style scoped lang="less" src="@/assets/css/turntable.less"></style>