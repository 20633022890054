var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TPop',{attrs:{"visible":_vm.isShow},on:{"update:visible":function($event){_vm.isShow=$event}}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"box",on:{"click":_vm.receive}},[(!_vm.isSuecces)?_c('div',_vm._l((_vm.imgList),function(item,index){return _c('div',{key:index,staticClass:"img-box",style:(`z-index:${Math.floor(item.scale * 100)};
      left:${item.left}%;
      top:calc(${item.top}% - var(--width));
      animation-delay:${item.delay}s;
      --rotate:${item.rotate}deg;
      --scale:${item.scale};
      --duration:${5 / item.scale}s`)},[_c('img',{attrs:{"src":require("@/assets/images/redEnvelopeRainCard.png"),"alt":""}})])}),0):_c('div',{staticClass:"suecces"},[_c('span',[_vm._v(_vm._s(_vm.rainData?.currency)+" "+_vm._s(_vm.numFormat(_vm.price)))]),_c('img',{attrs:{"src":require("@/assets/images/redEnvelopeRainSuecces.png"),"alt":""}}),_c('div',{staticClass:"rain-box-close",on:{"click":function($event){_vm.isShow = false}}})]),_c('img',{staticClass:"foot-img",attrs:{"src":require("@/assets/images/redEnvelopeRainFoot.png")}}),_c('img',{staticClass:"right-img",attrs:{"src":require("@/assets/images/redEnvelopeRainRight.png")}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }