<template>
  <TPop :visible.sync="isShow" :show-close="false">
    <div class="red-envelope-pop-box">
      <div class="red-envelope-pop-header">
        <img src="@/assets/images/red-envelope-pop-header-icon.png">
        <span>{{ $t("红包雨奖励") }}</span>
      </div>
      <div class="red-envelope-pop-card">
        <div class="price-title">{{ $t("最高奖金:") }}</div>
        <div class="price" :data-text="`${rainData.currency} ${numFormat(rainData.now_data.award_limit)}`">{{rainData.currency}} {{numFormat(rainData.now_data.award_limit)}}</div>
      </div>
      <div class="red-link">{{ $t("登录并参与") }}</div>
      <el-button class="btn button_fullwidth" @click="goLogin">{{ $t("参与") }}</el-button>
    </div>
    <img src="@/assets/images/red-envelope-pop-close.png" class="close-icon" @click="isShow = false">
  </TPop>
</template>

<script>
import TPop from "../TPop.vue";
export default {
  components: { TPop },
  data() {
    return {
      isShow: false,
    };
  },
  props:{
    rainData:{
      default:null
    }
  },
  methods: {
    goLogin(){
      this.isShow = false
      this.$router.push('?modal=LoginRegistration&data=tab-0')
    },
    show(){
      this.isShow = true
    },
  },
};
</script>

<style scoped lang="less" src="@/assets/css/redEnvelopeRainNone.less"></style>
